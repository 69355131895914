import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div className=' bg-[#f5f5f5] pb-5'>
        <div className='px-5 py-14 md:py-20 bg-[#797979] text-center'>
          <h3 className='text-white text-[28px] md:text-[40px] font-semibold'>Privacy Policy</h3>
          <div className='text-center text-sm lg:text-base text-yellow-400'><span><Link to="/" className='text-gray-300'>Home /</Link> Privacy Policy</span></div>
        </div>

        <div className='mt-6 container mx-auto shadow-xl'>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[20px] md:text-[25px] font-semibold'>Privacy Policy</div>
            <p className='mt-3'>This privacy policy sets out how <Link href="/" className=' text-blue-400'>elitedroptaxi.in</Link> collects, uses, maintains and discloses information collected from users (each, a “User”) of the website. This privacy policy applies to all services offered by One way Drop Taxi.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Use of collected information</div>
            <p className='mt-3'>Information Collection and Use One way Drop Taxi is the sole owner of the information collected on this website. One way Drop Taxi only has access to and collects information that Users voluntarily give via the website This information may include but is not limited to name, phone number, email address, and location data.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Use of collected information</div>
            <p>One way Drop Taxi uses collected information for the following purposes:</p>
            <ul className='mt-3'>
                <li>● To provide the services</li>
                <li>● To improve customer service</li>
                <li>● To personalize user experience</li>
                <li>● To send periodic emails</li>
            </ul>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Confidentiality and Security</div>
            <p className='mt-3'>One way Drop Taxi is committed to ensuring the security of users’ information. One way Drop Taxi implements a variety of security measures to maintain the safety of users’ information when Users access the website and perform transactions.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Disclosure of Information to Third Parties</div>
            <p className='mt-3'>One way Drop Taxi does not sell, trade, or rent User’s personal identification information to others. One way Drop Taxi may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with its business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Cookies</div>
            <p className='mt-3'>One way Drop Taxi uses "cookies" to enhance the User experience. Users’ web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or to alert them when cookies are being sent.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Changes to this Privacy Policy</div>
            <p className='mt-3'>One way Drop Taxi has the discretion to update this privacy policy at any time. When One way Drop Taxi does, Users will be notified via the website or by email. Users acknowledge and agree that it is their responsibility to review this privacy policy periodically and become aware of modifications.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Your Acceptance of these Terms</div>
            <p className='mt-3'>By using this website, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our website. Your continued use of the website following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Contacting Us</div>
            <p className='mt-3'>If you have any questions about this Privacy Policy, the practices of this website, or your dealings with this website, please contact us at <a href="mailto:eliteyourtrip@gmail.com" className=' text-blue-400'>eliteyourtrip@gmail.com</a></p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default PrivacyPolicy